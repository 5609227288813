import BaseStyle from '@primevue/core/base/style';

const classes = {
    root: 'p-inputgroupaddon'
};

export default BaseStyle.extend({
    name: 'inputgroupaddon',
    classes
});
